<template>
  <van-popup :closable="showClose" v-model="visible" position="bottom" :style="{ height: '30%' }" :close-on-click-overlay="false">
    <div>
      <div class="title">数据验证密码设置</div>
      <van-cell class="verify">
        <van-field v-model="pwd" placeholder="请输入新密码" />
        <van-field v-model="pwdTwo" placeholder="再次输入新密码" />
      </van-cell>
      <van-button class="btn" round type="info" @click="verify">保存</van-button>
    </div>
  </van-popup>
</template>
<script>
import {CheckPassWord} from '@/utils/validate'
export default {
  data () {
    return {
      pwd: '',
      pwdTwo: '',
      visible: false,
      showClose: false
    }
  },
  methods: {
    init (showClose) {
      this.pwd = ''
      this.pwdTwo = ''
      this.showClose = showClose
      this.visible = false
    },
    verify () {
      if (this.pwd == '') {
        this.$toast.fail('请先输入密码')
        return
      }
      if (!CheckPassWord(this.pwd)) {
        this.$toast.fail('密码必须由8位以上数字、字母、大写字母、特殊符号组成，特殊符号为：$!@#&*')
        return
      }
      if (this.pwd != this.pwdTwo){
        this.$toast.fail('两次密码输入的不一致')
        return
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/updatePasswordTwice'),
        method: 'post',
        data: this.$http.adornData({
          newPassword: this.pwd
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success('保存成功')
          this.$needUpdatePassword = false
          setTimeout(() => {
            this.visible = false
          }, 1500)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 40px;
  margin-top: 40px;
  color: #333333;
  text-align: center;
}
.verify {
  margin-top: 40px;
}
.verify >>> .van-field__control {
  text-align: left !important;
}
.btn {
  width: 400px;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
}
</style>
